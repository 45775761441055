import React from 'react'
import scroll from '../asset/images/scroll1.jpg'
const Scroll = () => {
    return (
        <div>
            <div className='scroll'>
                <img src={scroll} alt="scroll" />
            </div>
        </div>
    )
}

export default Scroll